<template>
  <div>
    <router-view @toggleClick="toggleClick"></router-view>
    <div v-if="toggleShow">
      <div
        class="position-fixed top-0 left-0 right-0 bottom-0"
        style="background-color: rgba(0, 0, 0, 0.5); z-index: 1000"
        @click.stop="toggleHide"
      ></div>
      <div class="el-toggle">
        <div class="space-between">
          <span class="el-toggle-title">提示！</span>
          <i class="el-icon-close pointer" @click="toggleHide"></i>
        </div>
        <div class="el-toggle-center row-center">
          <i class="el-icon-warning"></i>
          <div class="el-toggle-center-text">你当前身份处于招聘者,是否切换为求职者</div>
        </div>
        <div class="row-end">
          <el-button type="primary" size="small" @click="toggleSbmuit">确定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../api/user";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["hasLogin"]),
  },
  data() {
    return {
      IMobj: {
        is_flush: 0,
      },
      toggleShow: false,
    };
  },
  created() {
    if (this.hasLogin) {
      this.userSig();
      this.getUserInfo();
    }
  },
  // 组件销毁时将im退出登录
  beforeDestroy() {
    if (this.hasLogin) {
      let promise = this.$tim.logout();
      promise
        .then(function (imResponse) {})
        .catch(function (imError) {
          console.warn("logout error:", imError);
        });
    }
  },
  methods: {
    toggleClick(){
      this.toggleShow=true
    },
    toggleSbmuit() {
      api.toggleUser({ user_type: 1 }).then((res) => {
        if (res.code == 200) {
          this.$util.msg("切换成功,请重新点击或者刷新");
          this.getUserInfo();
         
          this.toggleShow = false;
          let promise = this.$tim.logout();
          promise
            .then((imResponse)=> {
              setTimeout(()=>{
                 this.userSig();
              },500)
            })
            .catch(function (imError) {
              console.warn("logout error:", imError);
            });
        }
      });
    },
    toggleHide() {
      this.toggleShow = false;
    },
    // 获取用户信息
    getUserInfo() {
      api.userDetail().then((res) => {
        if (res.code == 200) {
          this.$store.commit("SET_USER", res.data);
        }
      });
    },
    // 获取腾讯im
    userSig() {
      api.getUserSig(this.IMobj).then((res) => {
        if (res.code == 200) {
          this.$store.commit("SET_SIG", res.data);
          this.timLogin(res.data.userId, res.data.userSig);
        }
      });
    },
    // 登录腾讯im
    timLogin(userID, userSig) {
      let promise = this.$tim.login({ userID: userID.toString(), userSig });
      promise
        .then((imResponse) => {
          // 登录成功
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
          }
        })
        .catch((imError) => {
          this.IMobj.is_flush = 1;
          setTimeout(() => {
            this.userSig();
          }, 500);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.el-toggle {
  width: 420px;
  background: #ffffff;
  border-radius: 3px;
  padding: 10px 15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  .el-toggle-title {
    font-size: 18px;
    color: #303133;
  }
  .el-icon-close {
    color: #909399;
  }
  .el-toggle-center {
    margin: 20px 0px;
    color: #606266;
    font-size: 14px;
    .el-icon-warning {
      color: #e6a23c;
      font-size: 24px;
    }
    .el-toggle-center-text {
      margin-left: 16px;
    }
  }
}
</style>
